<template>
  <section>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>
          {{ office.name | uppercase }}
        </h1>

        <span>
          <v-btn
            class="mr-3"
            color="warning"
            :to="{ name: 'office-form', params: { uuid } }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>

          <v-btn
            color="error"
            @click="handleDeleteModal"
          >
            <v-icon left>mdi-delete</v-icon>
            Eliminar
          </v-btn>
        </span>
      </v-col>
    </v-row>

    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <b>Nombre:</b> {{ office.name }}
          </v-col>

          <v-col cols="12" sm="6">
            <b>Teléfono:</b> {{ office.phone }}
          </v-col>

          <v-col cols="12" sm="6">
            <p>
              <b>Responsables:</b>
            </p>

            <ul>
              <li
                v-for="admin in office.admins"
                :key="admin.uuid"
              >
                {{ admin.full_name }} ({{ admin.email }})
              </li>
            </ul>
          </v-col>

          <v-col cols="12" sm="6">
            <p>
              <b>Códigos Postales:</b>
            </p>

            <ul>
              <li
                v-for="postal_code in office.postal_codes"
                :key="postal_code"
              >
                {{ postal_code }}
              </li>
            </ul>
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <v-row>
          <v-col cols="12" lg="6">
            <p>
              <b>Servicios</b>
            </p>

            <div class="d-flex">
              <v-card
                v-for="service in office.services"
                :key="service.id"
                min-width="150"
                class="mr-5"
              >
                <v-img :src="getServiceIcon(service.icon)" class="service-icon mx-auto" />

                <v-card-title class="justify-center pa-1">{{ service.name }}</v-card-title>
              </v-card>
            </div>
          </v-col>

          <v-col cols="12" lg="6">
            <p>
              <b>Logo</b>
            </p>

            <v-img v-if="office.logo" :src="office.logo" width="150" height="150" />
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <AddressDetail :address="office.address" />
      </v-container>
    </v-card>

    <Modal
      :show.sync="showDeleteModal"
      @submit="remove"
    >
      <template #title>Eliminar</template>

      <template #body>
        ¿Deseas eliminar la oficina
        <b>{{ office.name | uppercase }}</b>
        ?
      </template>

      <template #action>Eliminar</template>
    </Modal>
  </section>
</template>

<script>
// API
import OfficeAPI from '@/api/office';

// Components
import AddressDetail from '@/components/address/Detail.vue';

// Mixins
import DetailMixin from '@/mixins/DetailMixin';

// Utils
import { getServiceIcon } from '@/utils/service';

export default {
  name: 'OfficeDetail',

  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  components: {
    AddressDetail,
  },

  mixins: [
    DetailMixin,
  ],

  data: () => ({
    office: {
      address: {},
      admins: [],
    },
    listRouteName: 'office-list',
  }),

  computed: {
    breadcrumbs() {
      const name = this.office.name ? this.office.name : '';
      return [
        {
          text: 'Sucursales',
          disabled: false,
          exact: true,
          to: { name: 'office-list' },
        },
        {
          text: name.toUpperCase(),
          disabled: true,
        },
      ];
    },
  },

  async created() {
    this.api = new OfficeAPI();
    await this.detail();
  },

  methods: {
    getServiceIcon,

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.office = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
